<template>
  <div>

    <!-- Buttons -->
    <crud-button :onClickList="fetchVergiDaires" :onClickAddNew="addNewVergiDaire" />

    <!-- Filters -->
    <VergiDaire-filters />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>

      </div>
  
      <b-table
        ref="VergiDairesTable"
        class="position-relative"
        :items="getVergiDaires"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
         <template #cell(sVergiDairesiAdi)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.sVergiDairesiAdi }}
            </b-link>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="toplamVergiDaire"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import useVergiDaire from '@/views/management/tanim-yonetim/vergidaire/useVergiDaire'
import VergiDaireFilters from './VergiDaireFilters.vue'

export default {
  components: {
    VergiDaireFilters,
    
  },
  methods: {
    addNewVergiDaire() {
      this.$router.push({ name: 'manage-vergidaire-create' })
    },
  },
  setup() {
    const {
      fetchVergiDaires,
      getVergiDaires,
      tableColumns,
      perPage,
      currentPage,
      toplamVergiDaire,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      VergiDairesTable,

      // UI

    } = useVergiDaire()

    return {
      fetchVergiDaires,
      getVergiDaires,
      tableColumns,
      perPage,
      currentPage,
      toplamVergiDaire,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      VergiDairesTable,

      // UI

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
