import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'

export default function useVergiDaire() {
  // Use toast
  const toast = useToast()
  
  const VergiDairesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Ad', key: 'sVergiDairesiAdi', sortable: true },
    { label: 'Saymanlık Kodu', key: 'sSaymanlikKodu', sortable: true },
    { label: 'Şehir', key: 'sSehirAdi', sortable: true },
    { label: 'İlçe', key: 'sIlce', sortable: true },
  ]

  const perPage = ref(10)
  const toplamVergiDaire = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getVergiDaireLocalFilters.sortBy
    },
    set(value) {
      store.commit('setVergiDaireFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getVergiDaireLocalFilters.sortDesc
    },
    set(value) {
      store.commit('setVergiDaireFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = VergiDairesTable.value ? VergiDairesTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamVergiDaire.value,
    }
  })

  const refetchData = () => {
    VergiDairesTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchVergiDaires = () => {
    store.dispatch('fetchVergiDaires', { })
  }

  const getVergiDaires = computed(() => {
    const VergiDaires = store.getters.getVergiDaires
    toplamVergiDaire.value = VergiDaires.length
    return VergiDaires
  })

  onMounted(fetchVergiDaires)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchVergiDaires,
    getVergiDaires,
    tableColumns,
    perPage,
    currentPage,
    toplamVergiDaire,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    VergiDairesTable,

  }
}
