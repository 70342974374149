<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>
            {{ $t('Vergi Dairesi Adı') }}
            </label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>
            {{ $t('Saymanlık Kodu') }}
            </label>
          <b-form-input
            v-model="sSaymanlikKodu"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  computed: {
    sSaymanlikKodu: {
      get() {
        return this.$store.getters.getVergiDaireLocalFilters.sSaymanlikKodu
      },
      set(value) {
        this.$store.commit('setVergiDaireFilter', { key: 'sSaymanlikKodu', value })
      },
    },
    
    isActiveFilter: {
      get() {
        return this.$store.getters.getVergiDaireLocalFilters.isActive
      },
      set(value) {
        this.$store.commit('setVergiDaireFilter', { key: 'isActive', value })
      },
    },
    
    queryFilter: {
      get() {
        return this.$store.getters.getVergiDaireLocalFilters.query
      },
      set(value) {
        this.$store.commit('setVergiDaireFilter', { key: 'query', value })
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
